import { useCallback, useState, useEffect } from "react";
import axios from "axios";
import "./MainComponent.css";
import {QrScanner} from '@yudiel/react-qr-scanner';
import ListItem from "./ListItem";
import PopupModal from "./PopupModal";
import ModeSelectorModal from "./ModeSelectorModal";

const MainComponent = () => {





  // OLD CODE
  // const [values, setValues] = useState([]);
  // const [value, setValue] = useState("");

  // const getAllNumbers = useCallback(async () => {
  //   // we will use nginx to redirect it to the proper URL
  //   const data = await axios.get("/api/values/all");
  //   setValues(data.data.rows.map(row => row.number));
  // }, []);

  // const saveNumber = useCallback(
  //   async event => {
  //     event.preventDefault();

  //     await axios.post("/api/values", {
  //       value
  //     });

  //     setValue("");
  //     getAllNumbers();
  //   },
  //   [value, getAllNumbers]
  // );

  // useEffect(() => {
  //   getAllNumbers();
  // }, []);
  // OLD CODE













  
  const defaultChecklist = Array(20).fill(false);
  const hardwarePrices = {
    "Muse 2": "250.00",
    "Muse S": "400.00",
    "OpenBCI": "1000.00",
    "EXG Pill": "150.00",
    "NeuroStimDuino": "300.00",
    "Arduino": "50.00",
    "Raspberry Pi": "150.00",
    "Meta Quest": "500.00",
  }
  const defaultPopupModals = {
    "checkGovId": false,
    "hardwarePickup": false,
    "swagBagPickup": false,
    "confirmResendOverEighteen": false,
    "confirmResendUnderEighteen": false,
    "confirmResendHardwareContract": false,
  }
  const baseIdUrl = "https://neuralberta.tech/nu?id=";
  const successTimeout = 2000; // number of ms to wait after successful scan.
  const mealtimeLabels = ["D0","B1","L1","D1","B2","L2","D2","B3","L3"];
  const hardwareLabels = ["Muse 2", "Muse S", "OpenBCI", "EXG Pill", "NeuroStimDuino", "Arduino", "Raspberry Pi", "Meta Quest"];
  var lastSuccessfulScan = new Date().getTime();

  var workingChecklist = defaultChecklist;
  var workingReservedDevice = "";
  var workingDepositAmount = "";
  const [checklist, setChecklist] = useState(defaultChecklist);
  const [reservedDevice, setReservedDevice] = useState("");
  const [depositAmount, setDepositAmount] = useState("");
  const [globalNuidRead, setGlobalNuidRead] = useState("");
  const [globalNameRead, setGlobalNameRead] = useState("");
  const [globalEmailRead, setGlobalEmailRead] = useState("");

  const [popupModals, setPopupModals] = useState(defaultPopupModals);
  const [foodModalSelectorModalShown, setFoodModalSelectorModalShown] = useState(false);
  const [hardwareModalSelectorModalShown, setHardwareModalSelectorModalShown] = useState(false);
  const [modalSelectorModalShown, setModalSelectorModalShown] = useState(false);
  const [modeSelectorTitle, setModeSelectorTitle] = useState("Mode Selector");
  const [modeSelectorColor, setModeSelectorColor] = useState("#f0f0f0");
  const [hardwarePickupPopupMessage, setHardwarePickupPopupMessage] = useState("error");
  const [swagBagPickupPopupMessage, setSwagBagPickupPopupMessage] = useState("error");
  const [confirmResendPopupMessage, setConfirmResendPopupMessage] = useState("error");
  const [scanMode, setScanMode] = useState(0);
  const [mealTimeMode, setMealTimeMode] = useState(-1);
  const [currentHardwareIndex, setCurrentHardwareIndex] = useState(-1);





  async function getOverEighteenContract(email, nUID) {
    await axios({
      method: "GET",
      url: `https://api.baserow.io/api/database/rows/table/219519/?user_field_names=true&filters=%7B%22filter_type%22%3A%22AND%22%2C%22filters%22%3A%5B%7B%22type%22%3A%22equal%22%2C%22field%22%3A%22Email%22%2C%22value%22%3A%22${email}%22%7D%5D%2C%22groups%22%3A%5B%5D%7D`,
      headers: {
        Authorization: "Token PenA5FCkuyAQhFxZZ0oul4JpcKzPyOs3"
      }
    }).then((res) => {
      if(res.data.count > 0) {
        workingChecklist[0] = true;
        // setCheckin(0, nUID); // don't set we just reread every time
      }

      console.log("here's the contract results, 18+:", res.data.count > 0);
    });
  }

  async function getUnderEighteenContract(email, nUID) {
    await axios({
      method: "GET",
      url: `https://api.baserow.io/api/database/rows/table/219520/?user_field_names=true&filters=%7B%22filter_type%22%3A%22AND%22%2C%22filters%22%3A%5B%7B%22type%22%3A%22equal%22%2C%22field%22%3A%22Email%22%2C%22value%22%3A%22${email}%22%7D%5D%2C%22groups%22%3A%5B%5D%7D`,
      headers: {
        Authorization: "Token PenA5FCkuyAQhFxZZ0oul4JpcKzPyOs3"
      }
    }).then((res) => {
      if(res.data.count > 0) {
        workingChecklist[2] = true;
        // setCheckin(2, nUID); // don't set we just reread every time
      }

      console.log("here's the contract results, under 18:", res.data.count > 0);
    });
  }

  async function getHardwareContract(email, nUID) {
    await axios({
      method: "GET",
      url: `https://api.baserow.io/api/database/rows/table/219521/?user_field_names=true&filters=%7B%22filter_type%22%3A%22AND%22%2C%22filters%22%3A%5B%7B%22type%22%3A%22equal%22%2C%22field%22%3A%22Email%22%2C%22value%22%3A%22${email}%22%7D%5D%2C%22groups%22%3A%5B%5D%7D`,
      headers: {
        Authorization: "Token PenA5FCkuyAQhFxZZ0oul4JpcKzPyOs3"
      }
    }).then((res) => {
      if(res.data.count > 0) {
        workingChecklist[7] = true;
        // setCheckin(7, nUID); // don't set we just reread every time
      }

      console.log("here's the contract results, hardware:", res.data.count > 0);
    });
  }


  


  async function getContractsByEmailAndNuid(email, nUID) {
    await Promise.all([
      getOverEighteenContract(email, nUID),
      getUnderEighteenContract(email, nUID),
      getHardwareContract(email, nUID),
    ]).then(res => {
      // setChecklist(workingChecklist);
      // console.log("done checking contract!", workingChecklist);
    });
  }



  async function getUserByNuid(nUid) {
    var result = { fullName: "Failed to find hacker" }

    await axios({
      method: "GET",
      url: `https://api.baserow.io/api/database/rows/table/212104/?user_field_names=true&filters=%7B%22filter_type%22%3A%22AND%22%2C%22filters%22%3A%5B%7B%22type%22%3A%22equal%22%2C%22field%22%3A%22nUID%22%2C%22value%22%3A%22${nUid}%22%7D%5D%2C%22groups%22%3A%5B%5D%7D`,
      headers: {
        Authorization: "Token PenA5FCkuyAQhFxZZ0oul4JpcKzPyOs3"
      }
    }).then((res) => {
      if(res.data.count > 1) {
        console.log("MORE THAN 1 RETURNED, ERROR");
      }

      var hacker = res.data.results[0];
      // console.log("all:", hacker);
      // console.log("name:", hacker["First Name"], hacker["Last Name"]);
      // console.log("email:", hacker["Email Address"]);
      // console.log("loc:", hacker["If yes, which location are you interested in attending in-person?"]);
      // console.log("date:", new Date(hacker["Timestamp"]).toLocaleDateString('en-CA', { year: 'numeric', month: 'long', day: 'numeric', }));

      console.log(`Found the hacker ${hacker["First Name"]}!`);

      result = {
        fullName: hacker["First Name"] + " " + hacker["Last Name"],
        email: hacker["Email Address"],
        location: hacker["If yes, which location are you interested in attending in-person?"],
        regDate: (new Date(hacker["Timestamp"]).toLocaleDateString('en-CA', { year: 'numeric', month: 'short', day: 'numeric', })).toString(),
      }
    });

    return result;
  }


  async function getUserHardwareReservationByNuid(nUid) {
    var result = { fullName: "Failed to find hacker" }

    await axios({
      method: "GET",
      url: `https://api.baserow.io/api/database/rows/table/219126/?user_field_names=true&filters=%7B%22filter_type%22%3A%22AND%22%2C%22filters%22%3A%5B%7B%22type%22%3A%22equal%22%2C%22field%22%3A%22nUID%22%2C%22value%22%3A%22${nUid}%22%7D%5D%2C%22groups%22%3A%5B%5D%7D`,
      headers: {
        Authorization: "Token PenA5FCkuyAQhFxZZ0oul4JpcKzPyOs3"
      }
    }).then(async (res) => {
      if(res != undefined && res.data.results[0] != undefined) {
        // Found a reserved hardware
        workingChecklist[6] = true;

        var hackersHardware = res.data.results[0];
        var waived = hackersHardware["DepositWaived"];
        workingReservedDevice = hackersHardware["Hardware"];
        workingDepositAmount = waived ? "$0.01" : ("$" + hardwarePrices[hackersHardware["Hardware"]]);
      } else {
        workingReservedDevice = "";
        workingDepositAmount = "";
      }


      // once we have the reservation info it is safe to search for etransfers
      await getUserHardwareDepositEtransferByNuid(nUid);


      console.log("done setting hardware reservation // deposit.");
    });

    return result;
  }


  async function getUserHardwareDepositEtransferByNuid(nUid) {
    var result = { fullName: "Failed to find hacker" }

    await axios({
      method: "GET",
      url: `https://api.baserow.io/api/database/rows/table/211732/?user_field_names=true&filters=%7B%22filter_type%22%3A%22AND%22%2C%22filters%22%3A%5B%7B%22type%22%3A%22equal%22%2C%22field%22%3A%22nUID%22%2C%22value%22%3A%22${nUid}%22%7D%5D%2C%22groups%22%3A%5B%5D%7D`,
      headers: {
        Authorization: "Token PenA5FCkuyAQhFxZZ0oul4JpcKzPyOs3"
      }
    }).then((res) => {
      if(res != undefined && res.data.results[0] != undefined) {
        // Found an e-transfer
        workingChecklist[8] = true;

        workingDepositAmount += (", paid $" + res.data.results[0]["Deposit Amount"]);
      }


      console.log("done setting etransfer");
    });

    return result;
  }

  
  async function getUserCheckinListByNuid(nUid) {
    var result = { fullName: "Failed to find hacker" }

    await axios({
      method: "GET",
      url: `https://api.baserow.io/api/database/rows/table/219115/?user_field_names=true&filters=%7B%22filter_type%22%3A%22AND%22%2C%22filters%22%3A%5B%7B%22type%22%3A%22equal%22%2C%22field%22%3A%22nUID%22%2C%22value%22%3A%22${nUid}%22%7D%5D%2C%22groups%22%3A%5B%5D%7D`,
      headers: {
        Authorization: "Token PenA5FCkuyAQhFxZZ0oul4JpcKzPyOs3"
      }
    }).then((res) => {
      var hackersCheckins = res.data.results;
      hackersCheckins.forEach((checkin) => {
        workingChecklist[parseInt(checkin["checkinID"])] = true;
      });

      console.log("done pulling hacker's checkins");
      // setChecklist(workingChecklist);
    });

    return result;
  }




  async function handleCallback(rawUrl) {

    // Throttle our requests to Baserow
    if(new Date() < (lastSuccessfulScan + successTimeout)) { return; }
    lastSuccessfulScan = new Date().getTime();
    // Throttle our requests to Baserow

    console.log("rawUrl:", rawUrl);
    var nUID = "";

    let displayMessage = document.getElementById("displayMessage");
    displayMessage.innerHTML = "";

    if(rawUrl.includes(baseIdUrl)) { // Valid ID URL
      nUID = rawUrl.replace(baseIdUrl, "");
      const userObject = await getUserByNuid(nUID);
      showUserInformation(rawUrl, userObject);
    }
    
    else { // Not a valid ID URL
      nUID = "";
      const userObject = { fullName: "Error: Not an ID URL", discord: "", email: ""};
      showUserInformation(rawUrl, userObject);
    }

    // setNuidSearchBoxValue(nUID);
    // if(nUID !== "") {
    //   updateUserCheckinByNuidAndCheckinType(nUID);
    // }
  }


  async function showUserInformation(rawUrl, userObject) {
    // Reset everything
    dismissAllModals(() => {});
    setGlobalNuidRead("");
    setGlobalNameRead("");
    setGlobalEmailRead("");
    workingChecklist = defaultChecklist;
    workingReservedDevice = "";
    workingDepositAmount = "";



    var displayRawNuid = document.getElementById("displayRawNuid");
    var displayEmail = document.getElementById("displayEmail");
    var displayName = document.getElementById("displayName");
    var displayRegDate = document.getElementById("displayRegDate");
    var displayLocation = document.getElementById("displayLocation");
    var displayRawUrl = document.getElementById("displayRawUrl");

    var nUID = "No Reading";
    var email = userObject.email || "No Reading";
    var fullName = userObject.fullName || "No Reading";
    var regDate = userObject.regDate || "No Reading";
    var location = userObject.location || "No Reading";

    if(rawUrl.includes(baseIdUrl)) {
      nUID = rawUrl.replace(baseIdUrl, "");
      setGlobalNuidRead(nUID);
    } else {
      nUID = "ERROR";
    }

    setGlobalNameRead(fullName);
    setGlobalEmailRead(email);

    if(location == "University of Alberta (main site)") {
      location = "YEG";
    } else if(location == "University of Calgary (sister event)") {
      location = "YYC";
    } else if(location == "University of Lethbridge (sister event)") {
      location = "YQL";
    } else if(location == "Not Attending In-Person") {
      location = "VIR";
    }



    if(displayRawNuid) { displayRawNuid.innerHTML = nUID; }
    if(displayName) { displayName.innerHTML = fullName; }
    if(displayEmail) { displayEmail.innerHTML = email; }
    if(displayRegDate) { displayRegDate.innerHTML = regDate; }
    if(displayLocation) { displayLocation.innerHTML = location; }
    if(displayRawUrl) { displayRawUrl.innerHTML = rawUrl; }


    // Populate new values
    Promise.all([
      getUserCheckinListByNuid(nUID),
      getUserHardwareReservationByNuid(nUID),
      getContractsByEmailAndNuid(email, nUID),
      // getUserHardwareDepositEtransferByNuid(nUID) // searched in the hardware reservation
    ]).then(([res]) => {

      //// Checkin mode checks ////
      // Check to see if we've ID'd them yet (only in checkin mode)
      if(!workingChecklist[1] && !workingChecklist[3] && scanMode == 1) {
        showModalNamed("checkGovId");
      }

      // Add a check for after oct 15th and if the location is VIR - warn if not
      //// Checkin mode checks ////


      //// Food mode checks ////
      var mealtimeStartingIndex = 11;
      if(!workingChecklist[mealtimeStartingIndex + mealTimeMode] && scanMode == 4) {
        setCheckin(mealtimeStartingIndex + mealTimeMode, nUID);
      }
      else if(scanMode == 4) {
        alert("it appears this hacker has already logged this mealtime.");
      }
      //// Food mode checks ////


      //// General checks ////
      // Have they completed everything needed for check in?
      if((workingChecklist[0] && workingChecklist[1] && workingChecklist[4]) || (workingChecklist[2] && workingChecklist[3] && workingChecklist[4])) { // signed waiver and id checked for right age, then picked up swag bag
        workingChecklist[5] = true;
      }
      // Have they completed everything needed for hardware pickup?
      if(workingChecklist[7] && workingChecklist[8] && workingChecklist[9]) { // signed contract, sent deposit, and picked up hardware
        workingChecklist[10] = true;
      }
      //// General checks ////



      // Finally, display everything
      console.log("Displaying checklist", workingChecklist);
      setChecklist(workingChecklist);
      setReservedDevice(workingReservedDevice);
      setDepositAmount(workingDepositAmount);

      // Refresh 1s later in case anything was missed in first run
      setTimeout(() => {
        setChecklist(workingChecklist);
        setReservedDevice(workingReservedDevice);
        setDepositAmount(workingDepositAmount);
      }, 1000);
    });

    
  }



  function setIdChecked(isOverEighteen) {
    var checkinIdToSet = "";
    if(isOverEighteen) {
      checkinIdToSet = "1";
    } else {
      checkinIdToSet = "3";
    }

    console.log("checking in", checkinIdToSet, "for", globalNuidRead);

    axios({
      method: "POST",
      url: "https://api.baserow.io/api/database/rows/table/219115/?user_field_names=true",
      headers: {
        Authorization: "Token PenA5FCkuyAQhFxZZ0oul4JpcKzPyOs3",
        "Content-Type": "application/json"
      },
      data: {
        "Timestamp": (new Date()).getTime(),
        "nUID": globalNuidRead,
        "checkinID": checkinIdToSet
      }
    }).then(() => {
      // update the list again so the change is reflected.
      getUserCheckinListByNuid(globalNuidRead);
      // console.log("new checkins loaded for nUID", globalNuidRead, workingChecklist, checklist);
    }).then(() => {
      setTimeout(() => {
        setChecklist(workingChecklist);
      }, 1000);
    });
  }



  function setCheckin(checkinNumber, nUID) {
    console.log("checking in for checkin number", checkinNumber, "for", nUID);

    workingChecklist[checkinNumber] = true; // hardcode it because pulling from baserow again is pretty slow. This is weird programming practice, so if it breaks look here first.

    axios({
      method: "POST",
      url: "https://api.baserow.io/api/database/rows/table/219115/?user_field_names=true",
      headers: {
        Authorization: "Token PenA5FCkuyAQhFxZZ0oul4JpcKzPyOs3",
        "Content-Type": "application/json"
      },
      data: {
        "Timestamp": (new Date()).getTime(),
        "nUID": nUID,
        "checkinID": checkinNumber
      }
    }).then(() => {
      // update the list again so the change is reflected.
      setTimeout(() => {
        getUserCheckinListByNuid(nUID);
      }, 1000);
    });
  }








  function showModalNamed(modalName) {
    console.log("showing modal named:", modalName);
    // var modalToShow = defaultPopupModals.copy();
    
    var modalToShow = Object.assign({}, defaultPopupModals);
    modalToShow[modalName] = true;
    // console.log("showing modal:", modalToShow);
    setPopupModals(modalToShow);
  }

  function openCheckinModeSelector() {
    setModalSelectorModalShown(true);
  }

  function startPickup() {
    // dismissAllModals(() => {});
    console.log("initiating pickup for global nUID", globalNuidRead, "workingChecklist", workingChecklist, "hook checklist", checklist);

    if(!checklist[7] && !checklist[8]) {
      setHardwarePickupPopupMessage(`Hacker with nUID "${globalNuidRead}" has not completed their contract nor deposit. Continue pickup?`);
      showModalNamed("hardwarePickup");
    }
    else if(!checklist[7]) {
      setHardwarePickupPopupMessage(`Hacker with nUID "${globalNuidRead}" has not completed their contract. Continue pickup?`);
      showModalNamed("hardwarePickup");
    }
    else if(!checklist[8]) {
      setHardwarePickupPopupMessage(`Hacker with nUID "${globalNuidRead}" has not sent their deposit. Continue pickup?`);
      showModalNamed("hardwarePickup");
    }

    else {
      setHardwarePickupPopupMessage(`Hacker with nUID "${globalNuidRead}" is safe to pickup hardware!`);
      showModalNamed("hardwarePickup");
      // modal calls the complete pickup func
    }
  }

  function completePickup() {
    if(globalNuidRead !== "" && globalNuidRead !== undefined) {
      setCheckin(9, globalNuidRead);
      console.log("done pick up!");
    } 
    else {
      console.log("UNABLE TO COMPLETE PICKUP, no provided nUID");
    }
  }





  function startPickupSwagBag() {
    console.log("initiating swag bag pickup for global nUID", globalNuidRead, "workingChecklist", workingChecklist, "hook checklist", checklist);

    if(!checklist[0] && !checklist[1] && !checklist[2] && !checklist[3]) {
      setSwagBagPickupPopupMessage(`Hacker with nUID "${globalNuidRead}" has not completed anything and should not be permitted to pickup. Continue pickup?`);
      showModalNamed("swagBagPickup");
    }

    if(!((checklist[0] && checklist[1]) || (checklist[2] && checklist[3]))) {
      setSwagBagPickupPopupMessage(`Hacker with nUID "${globalNuidRead}" appears to have a mismatch with ID age check and hacker waiver. Continue pickup?`);
      showModalNamed("swagBagPickup");
    }

    else {
      setSwagBagPickupPopupMessage(`Hacker with nUID "${globalNuidRead}" is safe to pickup their swag bag!`);
      showModalNamed("swagBagPickup");
      // modal calls the complete pickup func
    }
  }

  function completePickupSwagBag() {
    if(globalNuidRead !== "" && globalNuidRead !== undefined) {
      setCheckin(4, globalNuidRead);
      console.log("done swag bag pick up!");
    } 
    else {
      console.log("UNABLE TO COMPLETE PICKUP, no provided nUID");
    }
  }







  function resendOverEighteenWaiver() {
    if(globalNuidRead === undefined || globalNuidRead == "" || globalNameRead === undefined || globalNameRead == "" || globalEmailRead === undefined || globalEmailRead == "") {
      alert("oops, missing a name, nUID, and/or email to send waiver to! Please scan user again.");
      return;
    }

    setConfirmResendPopupMessage(`Resend standard waiver to ${globalNameRead} with nUID: ${globalNuidRead} and email ${globalEmailRead}?`);
    showModalNamed("confirmResendOverEighteen");
  }
  async function completeResendOverEighteenWaiver() {
    if(globalNuidRead === undefined || globalNuidRead == "") {
      console.log("no nUID, error out.");
      return;
    }

    await axios({
      method: "POST",
      url: "https://api.baserow.io/api/database/rows/table/220091/?user_field_names=true",
      headers: {
        Authorization: "Token PenA5FCkuyAQhFxZZ0oul4JpcKzPyOs3",
        "Content-Type": "application/json"
      },
      data: {
        "Email": globalEmailRead,
        "Hacker Name": globalNameRead,
        "nUID": globalNuidRead
      }
    }).then(() => {
      alert("Successfully sent!");
    });
  }


  function resendUnderEighteenWaiver() {
    if(globalNuidRead === undefined || globalNuidRead == "" || globalNameRead === undefined || globalNameRead == "" || globalEmailRead === undefined || globalEmailRead == "") {
      alert("oops, missing a name, nUID, and/or email to send waiver to! Please scan user again.");
      return;
    }

    setConfirmResendPopupMessage(`Resend minor's waiver to ${globalNameRead} with nUID: ${globalNuidRead} and email ${globalEmailRead}?`);
    showModalNamed("confirmResendUnderEighteen");
  }
  async function completeResendUnderEighteenWaiver() {
    if(globalNuidRead === undefined || globalNuidRead == "") {
      console.log("no nUID, error out.");
      return;
    }

    let parentEmail = prompt("Email of Parent'Guardian", "");

    await axios({
      method: "POST",
      url: "https://api.baserow.io/api/database/rows/table/220127/?user_field_names=true",
      headers: {
        Authorization: "Token PenA5FCkuyAQhFxZZ0oul4JpcKzPyOs3",
        "Content-Type": "application/json"
      },
      data: {
        "Email": globalEmailRead,
        "Hacker Name": globalNameRead,
        "ParentEmail": parentEmail,
        "ParentName": ("Parent/Guardian of " + globalNameRead.toString()),
        "nUID": globalNuidRead
      }
    }).then(() => {
      alert("Successfully sent!");
    });
  }



  function resendHardwareContract() {
    if(globalNuidRead === undefined || globalNuidRead == "" || globalNameRead === undefined || globalNameRead == "" || globalEmailRead === undefined || globalEmailRead == "") {
      alert("oops, missing a name, nUID, and/or email to send waiver to! Please scan user again.");
      return;
    }

    setHardwareModalSelectorModalShown(true);
    // these are called in the hardwareModalSelectorModalShown modal
    // setConfirmResendPopupMessage(`Resend hardware contract to ${globalNameRead} with nUID: ${globalNuidRead} and email ${globalEmailRead}?`);
    // showModalNamed("confirmResendHardwareContract");
  }
  async function completeResendHardwareContract() {
    if(globalNuidRead === undefined || globalNuidRead == "") {
      console.log("no nUID, error out.");
      return;
    }

    var hardwareName = hardwareLabels[currentHardwareIndex];
    var depositAmount = hardwarePrices[hardwareName];

    await axios({
      method: "POST",
      url: "https://api.baserow.io/api/database/rows/table/220129/?user_field_names=true",
      headers: {
        Authorization: "Token PenA5FCkuyAQhFxZZ0oul4JpcKzPyOs3",
        "Content-Type": "application/json"
      },
      data: {
        "Email": globalEmailRead,
        "Hacker Name": globalNameRead,
        "HardwareName": hardwareName,
        "RentalAmount": "0.00",
        "DepositAmount": depositAmount,
        "TotalAmount": depositAmount,
        "nUID": globalNuidRead
      }
    }).then(() => {
      alert("Successfully sent!");
    });
  }






  function dismissAllModals(callback) {
    setPopupModals(defaultPopupModals);
    setModalSelectorModalShown(false);
    setFoodModalSelectorModalShown(false);
    setHardwareModalSelectorModalShown(false);
    callback();
  }

  function dismissModeSelectorModal(mode) {
    dismissAllModals(() => {});
    setMealTimeMode(-1);

    if(mode == 1) {
      setScanMode(1);
      setModeSelectorColor("#4287f5");
      setModeSelectorTitle("Checkin Mode");
    }

    // Deprecated v
    else if(mode == 2) {
      setScanMode(2);
      setModeSelectorColor("#eb34e1");
      setModeSelectorTitle("Hardware Pickup Mode");
    }
    // Deprecated ^

    else if(mode == 3) {
      setScanMode(3);
      setModeSelectorColor("#34eb6e");
      setModeSelectorTitle("Hardware Return Mode");
    }

    else if(mode == 4) {
      setScanMode(4);
      setModeSelectorColor("#fcba03");
      setModeSelectorTitle("Food Mode");
      // if(mealTimeMode == -1) {
      setFoodModalSelectorModalShown(true);
      // }
    }

    else if(mode == 0) {
      setScanMode(0);
      setModeSelectorColor("#f0f0f0");
      setModeSelectorTitle("Mode Selector");
    }
  }


  function dismissFoodModeSelectorModal(mode) {
    setMealTimeMode(mode);
    setModeSelectorTitle("Food Mode | " + mealtimeLabels[mode]);

    dismissAllModals(() => {});
  }


  function dismissHardwareModeSelectorModal(hardwareIndex) {
    // setCurrentHardwareIndex(-1);

    // console.log("set the hardware to index:", hardwareIndex);
    var hdw = hardwareLabels[hardwareIndex];
    var dpst = hardwarePrices[hdw];

    setCurrentHardwareIndex(hardwareIndex);

    setConfirmResendPopupMessage(`Hacker: ${globalNameRead} \n\nnUID: ${globalNuidRead} \nEmail: ${globalEmailRead} \nHardware: ${hdw} \nDeposit: $${dpst} \nReady to send?`);
    showModalNamed("confirmResendHardwareContract");

    // dismissAllModals(() => {});
    setHardwareModalSelectorModalShown(false);
  }







  return (
    <div>
      {/* <button onClick={getAllNumbers}>Get all numbers</button>
      <br />
      <span className="title">Values</span>
      <div className="values">
        {values.map(value => (
          <div className="value">{value}</div>
        ))}
      </div>
      <form className="form" onSubmit={saveNumber}>
        <label>Enter your value: </label>
        <input
          value={value}
          onChange={event => {
            setValue(event.target.value);
          }}
        />
        <button>Submit</button>
      </form> */}



      <PopupModal
        shown={popupModals["checkGovId"]}
        popupMessage={"Please check the hacker's identification (Driver's License, Passport, etc.) for their age. Be careful, this CANNOT be undone."}
        primaryLabel={"Birthday BEFORE Nov 16, 2005"}
        secondaryLabel={"Birthday AFTER Nov 16, 2005"}
        primaryCallback={() => {dismissAllModals( () => {setIdChecked(true)} )}}    // set ID over 18
        secondaryCallback={() => {dismissAllModals( () => {setIdChecked(false)} )}} // set ID under 18
      />

      <PopupModal
        shown={popupModals["hardwarePickup"]}
        popupMessage={hardwarePickupPopupMessage}
        primaryLabel={"Complete Pickup"}
        secondaryLabel={"CANCEL"}
        primaryCallback={() => {dismissAllModals(completePickup)}}
        secondaryCallback={() => {dismissAllModals( () => {console.log("CANCELLING PICKUP")} )}}
      />


      <PopupModal
        shown={popupModals["swagBagPickup"]}
        popupMessage={swagBagPickupPopupMessage}
        primaryLabel={"Complete Pickup"}
        secondaryLabel={"CANCEL"}
        primaryCallback={() => {dismissAllModals(completePickupSwagBag)}}
        secondaryCallback={() => {dismissAllModals( () => {console.log("CANCELLING PICKUP")} )}}
      />

      <PopupModal
        shown={popupModals["confirmResendOverEighteen"]}
        popupMessage={confirmResendPopupMessage}
        primaryLabel={"Resend 18+ Contract"}
        secondaryLabel={"CANCEL"}
        primaryCallback={() => {dismissAllModals(completeResendOverEighteenWaiver)}}
        secondaryCallback={() => {dismissAllModals( () => {console.log("CANCELLING RESEND")} )}}
      />

      <PopupModal
        shown={popupModals["confirmResendUnderEighteen"]}
        popupMessage={confirmResendPopupMessage}
        primaryLabel={"Resend <18 Contract"}
        secondaryLabel={"CANCEL"}
        primaryCallback={() => {dismissAllModals(completeResendUnderEighteenWaiver)}}
        secondaryCallback={() => {dismissAllModals( () => {console.log("CANCELLING RESEND")} )}}
      />

      <PopupModal
        shown={popupModals["confirmResendHardwareContract"]}
        popupMessage={confirmResendPopupMessage}
        primaryLabel={"Resend HW Contract"}
        secondaryLabel={"CANCEL"}
        primaryCallback={() => {dismissAllModals(completeResendHardwareContract)}}
        secondaryCallback={() => {dismissAllModals( () => {console.log("CANCELLING RESEND")} )}}
      />





      <ModeSelectorModal
        shown={modalSelectorModalShown}
        popupMessage={"Select scanner mode"}
        labels={[
          "Checkin",
          // "Hardware Pickup",
          "Hardware Return",
          "Food",
          "Default | only read, no write",
        ]}
        callbacks={[
          () => {dismissModeSelectorModal(1)},
          // () => {dismissModeSelectorModal(2)},
          () => {dismissModeSelectorModal(3)},
          () => {dismissModeSelectorModal(4)},
          () => {dismissModeSelectorModal(0)},
        ]}
        colors={[
          "#4287f5",
          // "#eb34e1",
          "#34eb6e",
          "#fcba03",
          "#f0f0f0",
        ]}
      />




      <ModeSelectorModal
        shown={foodModalSelectorModalShown}
        popupMessage={"Which mealtime is it?"}
        labels={mealtimeLabels}
        callbacks={[
          () => {dismissFoodModeSelectorModal(0)},
          () => {dismissFoodModeSelectorModal(1)},
          () => {dismissFoodModeSelectorModal(2)},
          () => {dismissFoodModeSelectorModal(3)},
          () => {dismissFoodModeSelectorModal(4)},
          () => {dismissFoodModeSelectorModal(5)},
          () => {dismissFoodModeSelectorModal(6)},
          () => {dismissFoodModeSelectorModal(7)},
          () => {dismissFoodModeSelectorModal(8)},
        ]}
        colors={[
          "#f0f0f0",
          "#f0f0f0",
          "#f0f0f0",
          "#f0f0f0",
          "#f0f0f0",
          "#f0f0f0",
          "#f0f0f0",
          "#f0f0f0",
          "#f0f0f0",
        ]}
      />



      <ModeSelectorModal
        shown={hardwareModalSelectorModalShown}
        popupMessage={"Resend contract for which hardware device?"}
        labels={hardwareLabels}
        callbacks={[
          () => {dismissHardwareModeSelectorModal(0)},
          () => {dismissHardwareModeSelectorModal(1)},
          () => {dismissHardwareModeSelectorModal(2)},
          () => {dismissHardwareModeSelectorModal(3)},
          () => {dismissHardwareModeSelectorModal(4)},
          () => {dismissHardwareModeSelectorModal(5)},
          () => {dismissHardwareModeSelectorModal(6)},
          () => {dismissHardwareModeSelectorModal(7)},
          () => {dismissHardwareModeSelectorModal(8)},
        ]}
        colors={[
          "#f0f0f0",
          "#f0f0f0",
          "#f0f0f0",
          "#f0f0f0",
          "#f0f0f0",
          "#f0f0f0",
          "#f0f0f0",
          "#f0f0f0",
          "#f0f0f0",
        ]}
      />



      <div className="pageContainer">  
        <div className="mainContainer">
          <div className="infoContainer">
            {/* <img src="/natHACKSTitle.png" className="titleImage"></img> */}
            <div className="selectedUserFieldContainer">
              <div className="selectedUserFieldTitle">nUID:</div>
              <div className="selectedUserFieldContent" id="displayRawNuid">No Reading</div>
            </div>
            <div className="selectedUserFieldContainer">
              <div className="selectedUserFieldTitle">Name:</div>
              <div className="selectedUserFieldContent" id="displayName">No Reading</div>
            </div>
            <div className="selectedUserFieldContainer">
              <div className="selectedUserFieldTitle">Email:</div>
              <div className="selectedUserFieldContent" id="displayEmail">No Reading</div>
            </div>
            <div className="selectedUserFieldContainer">
              <div className="selectedUserFieldTitle">RegDate:</div>
              <div className="selectedUserFieldContent" id="displayRegDate">No Reading</div>
            </div>
            <div className="selectedUserFieldContainer">
              <div className="selectedUserFieldTitle">Location:</div>
              <div className="selectedUserFieldContent" id="displayLocation">No Reading</div>
            </div>
            <div className="selectedUserFieldContainer">
              <div className="selectedUserFieldTitle">URL:</div>
              <div className="selectedUserFieldContentSmall" id="displayRawUrl">No Reading</div>
            </div>


            <div className="selectedUserFieldContainer">
              <div className="selectedUserFieldTitle">Message:</div>
              <div className="selectedUserFieldContent errorText" id="displayMessage"></div>
            </div>
          </div>


          <div className="qrContainer">
            <QrScanner
              onDecode={(result) => {
                handleCallback(result);
              }}
              onError={(error) => console.log(error?.message)}
            />
          </div>
        </div>





        <div className="listContainer">
          <ListItem
            itemId={"0"}
            checklist={checklist}
            itemTitle={"Hacker Waiver, 18+"}
            resendCallback={resendOverEighteenWaiver}
            resendLabel={"Resend"}
          />
          {/* PLAN B - FAILED SOLUTION if the nice way doesn't work, we can tie "resend" into just downloading the formatted .csv you'd need to do bulk send. */}

          <ListItem
            itemId={"1"}
            checklist={checklist}
            itemTitle={"ID Checked, 18+"}
          />

          <ListItem
            itemId={"2"}
            checklist={checklist}
            itemTitle={"Hacker Waiver, <18 "}
            resendCallback={resendUnderEighteenWaiver}
            resendLabel={"Resend"}
          />

          <ListItem
            itemId={"3"}
            checklist={checklist}
            itemTitle={"ID Checked, <18"}
          />

          <ListItem
            itemId={"4"}
            checklist={checklist}
            itemTitle={"Picked up Swag Bag"}
            resendCallback={startPickupSwagBag}
            resendLabel={"StartPickup"}
          />




          {/* This is auto classified based on the other fields */}
          <ListItem
            itemId={"5"}
            checklist={checklist}
            itemTitle={"- COMPLETED CHECK IN -"}
          />
          {/* This is auto classified based on the other fields */}




          <div className="listDivider"></div>



          <ListItem
            itemId={"6"}
            checklist={checklist}
            itemTitle={"Reserved Hardware"}
            specifier={reservedDevice}
          />

          <ListItem
            itemId={"7"}
            checklist={checklist}
            itemTitle={"Hardware Contract"}
            resendCallback={resendHardwareContract}
            resendLabel={"Resend"}
          />


          {/* just send an email to them with their nUID? */}
          <ListItem
            itemId={"8"}
            checklist={checklist}
            itemTitle={"Hardware Deposit"}
            // resendCallback={() => console.log("send email with nUID?")}
            resendLabel={"Resend"}
            specifier={depositAmount}
          />
          {/* just send an email to them with their nUID? */}


          <ListItem
            itemId={"9"}
            checklist={checklist}
            itemTitle={"Received Hardware"}
            resendCallback={startPickup}
            resendLabel={"StartPickup"}
          />


          {/* This is auto classified based on the other fields */}
          <ListItem
            itemId={"10"}
            checklist={checklist}
            itemTitle={"- COMPLETED HARDWARE PICK UP -"}
          />
          {/* This is auto classified based on the other fields */}



          <div className="listDivider"></div>



          <ListItem
            itemId={"11"}
            checklist={checklist}
            itemTitle={"D0"}
          />
          <ListItem
            itemId={"12"}
            checklist={checklist}
            itemTitle={"B1"}
          />
          <ListItem
            itemId={"13"}
            checklist={checklist}
            itemTitle={"L1"}
          />
          <ListItem
            itemId={"14"}
            checklist={checklist}
            itemTitle={"D1"}
          />
          <ListItem
            itemId={"15"}
            checklist={checklist}
            itemTitle={"B2"}
          />
          <ListItem
            itemId={"16"}
            checklist={checklist}
            itemTitle={"L2"}
          />
          <ListItem
            itemId={"17"}
            checklist={checklist}
            itemTitle={"D2"}
          />
          <ListItem
            itemId={"18"}
            checklist={checklist}
            itemTitle={"B3"}
          />
          <ListItem
            itemId={"19"}
            checklist={checklist}
            itemTitle={"L3"}
          />



          <div className="listDivider"></div>



          <ListItem
            itemId={"20"}
            checklist={checklist}
            itemTitle={"Returned Hardware"}
          />



        </div>




        <div className="checkinModeButton" style={{"backgroundColor": modeSelectorColor}} onClick={openCheckinModeSelector}>{modeSelectorTitle}</div>

        <div class="spacer"></div>

      </div>
    </div>
  );
};

export default MainComponent;
