import { useCallback, useState, useEffect } from "react";
import axios from "axios";
import "./PopupModal.css";

const PopupModal = ({shown, popupMessage, primaryLabel, secondaryLabel, primaryCallback, secondaryCallback}) => {

  // var isCompleted = checklist[parseInt(itemId)];
  // var outputTitle = `${itemTitle}${(specifier != "" && specifier != undefined) ? " (" + specifier + ")" : ""}`

  if(shown) {
    return (
      <div className="popupModalBackground">
        <div className="popupModal">
          <div className="popupButtonMessage">{popupMessage}</div>
  
          <div className="popupButtonContainer">
            <div className="popupButton popupButtonPrimary" onClick={primaryCallback}>{primaryLabel}</div>
            <div className="popupButton popupButtonSecondary" onClick={secondaryCallback}>{secondaryLabel}</div>
          </div>
  
        </div>
      </div>
    );
  }

  return <div></div>;
};

export default PopupModal;
