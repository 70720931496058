import { useCallback, useState, useEffect } from "react";
import axios from "axios";
import "./MainComponent.css";

const ListItem = ({itemId, itemTitle, checklist, resendCallback, specifier, resendLabel}) => {

  var isCompleted = checklist[parseInt(itemId)];
  var outputTitle = `${itemTitle}${(specifier != "" && specifier != undefined) ? " (" + specifier + ")" : ""}`

  if (resendCallback != undefined) {
    return (
      <div>
        <div className="listItem">
          <div className="listMain">
            <div className={`booleanDisplay ${isCompleted ? "booleanDisplayTrue" : "booleanDisplayFalse"}`}></div>
            <div className={`textContainer ${isCompleted ? "textContainerTrue" : "textContainerFalse"}`}>{outputTitle}</div>
          </div>

          <div className="resendButton">
            <div className="resendText" onClick={resendCallback}>{resendLabel}</div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="listItem">
        <div className="listMain">
          <div className={`booleanDisplay ${isCompleted ? "booleanDisplayTrue" : "booleanDisplayFalse"}`}></div>
          <div className={`textContainer ${isCompleted ? "textContainerTrue" : "textContainerFalse"}`}>{outputTitle}</div>
        </div>
      </div>
    </div>
  );
};

export default ListItem;
