import { useCallback, useState, useEffect } from "react";
import axios from "axios";
import "./ModeSelectorModal.css";

const ModeSelectorModal = ({shown, popupMessage, labels, callbacks, colors}) => {

  var constructedButtons = [];
  for (let i = 0; i < labels.length; i++) {
    constructedButtons.push(
      <div className="popupButton" style={{color: colors[i], borderColor: colors[i]}} onClick={callbacks[i]}>{labels[i]}</div>
    );
  } 

  if(shown) {
    return (
      <div className="popupModalBackground">
        <div className="popupModal">
          <div className="popupButtonMessage">{popupMessage}</div>
  

          <div className="popupButtonContainer">
            {constructedButtons}
          </div>
  
        </div>
      </div>
    );
  }

  return <div></div>;
};

export default ModeSelectorModal;
